//
// authentication.scss
//

// authentication home icon

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo {
    .auth-logo-dark {
        display: $display-block;
    }

    .auth-logo-light {
        display: $display-none;
    }
}

.auth-body-bg {
    background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
    input[type="input"]+.btn .mdi-eye-outline {
        &:before {
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg {
    background-color: rgba($primary, 0.25);
    display: flex;
    min-height: 100vh;

    &::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay {
        // background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: white;
    min-height: 100%;
    box-shadow: $box-shadow-lg;
    border-radius: 5px;
    //  padding: 1.5rem;
}

.auth-background-img {
    // background-image: url("./../../../../assets/images/Kara-Logo.png");
    height: 100vh;
    background-repeat: no-repeat;
    position: relative;
    //object-fit: cover;
    // object-fit: cover;
    background-size: cover;
    width: 100%;
}

.auth-review-carousel {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    background-color: rgba($primary, 0.25);
                }

                &.active,
                &:hover {
                    span {
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.start-page-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.auth-navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: $header-height;
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

    @media (max-width: 576px) {
        height: auto;
        padding-top: 1rem;
        flex-direction: column;
        align-items: stretch;

        .start-brand-box {
            text-align: center;
        }
    }
}

.start-brand-box {
    text-align: start;

    .logo-footer {
        .logo-sm {
            display: none;
        }
    }

    .logo-light {
        display: none;
    }
}

.auth-body-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        //padding: 1rem;
        width: 700px;
        -webkit-transition: $transition-fade;
        -moz-transition: $transition-fade;
        -o-transition: $transition-fade;
        transition: $transition-fade;
    }

    @media (max-width: 768px) {
        .auth-form-wrapper {
            width: 350;
        }
    }
}