.create-question-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2,
h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.question-type-select,
.question-textarea,
.option-input,
.correct-answer-input,
.answer-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.add-option-btn,
.add-question-btn,
.grade-exam-btn {
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-option-btn:hover,
.add-question-btn:hover,
.grade-exam-btn:hover {
  background-color: #0056b3;
}

.questions-list {
  list-style-type: none;
  padding-left: 0;
}

.question-preview {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.question-preview strong {
  display: block;
  margin-bottom: 5px;
}